import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Select,
  Card,
  Input,
  Row,
  Col,
  DatePicker,
  Avatar,
  Upload,
  Table,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const SelectTableComponent = (props) => {
  // const options = cloneData(WORKLOAD).map((x, i) => {
  //   x.label = `${i + 1}. ${x.label}`;
  //   return x;
  // });

  const location = useLocation();
  let locationPath = location.pathname.slice(1);

  const mode = props.selectOption.filter(
    (x) => x.value === props.selectState
  )?.[0]?.label;

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex gap-2 justify-center items-center bg-orange-300 p-4 rounded-lg">
        <label className="font-medium">ค้นหา :</label>
        <Select
          // showSearch
          placeholder={mode}
          // onSearch={}
          defaultValue={mode}
          onChange={props.setSelectState}
          className="w-2/5"
          options={props.selectOption}
        />
      </div>
      <Card
        type="inner"
        title={mode}
        extra={
          locationPath === "workload" ? (
            <Button
              type="primary"
              className="bg-blue-500 flex justify-center items-center"
              onClick={() => props.onChangeModal()}
            >
              <PlusCircleOutlined />
              เพิ่ม
            </Button>
          ) : null
        }
        headStyle={{ background: "#ccc" }}
        style={{
          boxShadow:
            "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
        }}
      >
        <Table
          columns={props.columns.filter((x) => x)}
          dataSource={props.dataSource}
          className={
            "w-full rounded overflow-y-hidden overflow-x-scroll scrollbar-hide shadow-lg"
          }
          bordered
          pagination={{
            size: "small",
            total: props.totalPage,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            current: props.skipPage.skip + 1,
            pageSize: props.skipPage.limit,
            className: "px-4",
            onChange: (page, row) => props.onChangeSkip(page, row),
          }}
          locale={{
            triggerDesc: null,
            triggerAsc: null,
            cancelSort: null,
          }}
        />
      </Card>
    </div>
  );
};

export default SelectTableComponent;
