import React, { useEffect, useState } from "react";
import { Button, Space, Tag, Tooltip } from "antd";
import {
  FilePdfOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { API_ENDPOINT, APPROVE_STATUS } from "../config/index";
import Swal from "sweetalert2";
import { getData, isDeanOrRong, postData } from "../utils";
import SelectTableComponent from "../components/selecttable";
import moment from "moment";
import { FormRoom } from "../pdf/formRoom";
import { FormCar } from "../pdf/formCar";
import { FormLeave } from "../pdf/formLeave";
import { useSelector } from "react-redux";
import LoadingComponent from "../components/loading";
import { FormAsset } from "../pdf/formAsset";

const listType = {
  leave: {
    label: "ใบลา",
    table: "leave AS x",
    projection: `x.id, x.user_id, NULLIF(CONCAT_WS(' ', a.title, a.firstname, a.middlename, a.lastname), '') AS name,
    a.position_id, position_name, a.phone, type_leave_id, type_name, a.type_id, one_signature, two_signature,
    scheduled, leave_name, personal_affairs_detail, address, leave_name AS leavename,
    CASE
      WHEN one_status = 'true' AND two_status = 'true' THEN 'true'\n
      WHEN one_status = 'false' AND two_status = 'false' THEN 'false'\n
      ELSE NULL
    END AS status,
    time_start, time_end, one_status, two_status, sucess_time, x.create_at`,
    join: [
      {
        type: "LEFT",
        table: "core_role AS a",
        on: "x.user_id = a.user_id",
      },
      {
        type: "LEFT",
        table: "type_leave AS tl",
        on: "x.type_leave_id = tl.id",
      },
      {
        type: "LEFT",
        table: "position AS p",
        on: "a.position_id = p.id",
      },
      {
        type: "LEFT",
        table: "type AS t",
        on: "a.type_id = t.id",
      },
    ],
  },
  car: {
    label: "จองรถ",
    table: "booking_a_car AS x",
    projection: `x.id, x.user_id, NULLIF(CONCAT_WS(' ', a.title, a.firstname, a.middlename, a.lastname), '') AS name,
    a.position_id, position_name, a.phone, a.type_id, type_name, one_signature, two_signature,
    car_id, c.car_name, c.car_registration, travel, fuel_cost, driver_cost, accommodation_cost,
    other_expenses, other_detail, agree, agree_detail, people,
    CASE
      WHEN one_status = 'true' AND two_status = 'true' THEN 'true'\n
      WHEN one_status = 'false' AND two_status = 'false' THEN 'false'\n
      ELSE NULL
    END AS status,
    time_start, time_end, one_status, two_status, sucess_time, x.create_at`,
    join: [
      {
        type: "LEFT",
        table: "core_role AS a",
        on: "x.user_id = a.user_id",
      },
      {
        type: "LEFT",
        table: "car AS c",
        on: "x.car_id = c.id",
      },
      {
        type: "LEFT",
        table: "position AS p",
        on: "a.position_id = p.id",
      },
      {
        type: "LEFT",
        table: "type AS t",
        on: "a.type_id = t.id",
      },
    ],
  },
  room: {
    label: "จองห้อง",
    table: "booking_a_room AS x",
    projection: `x.id, x.user_id, NULLIF(CONCAT_WS(' ', a.title, a.firstname, a.middlename, a.lastname), '') AS name,
    a.position_id, position_name, dept_name, type_name, a.phone, one_signature, two_signature,
    room_id, room_name, floor, size, people, description,
    CASE
      WHEN one_status = 'true' THEN 'true'\n
      WHEN one_status = 'false' THEN 'false'\n
      ELSE NULL
    END AS status,
    time_start, time_end, one_status, two_status, sucess_time, x.create_at`,
    join: [
      {
        type: "LEFT",
        table: "core_role AS a",
        on: "x.user_id = a.user_id",
      },
      {
        type: "LEFT",
        table: "room AS r",
        on: "x.room_id = r.id",
      },
      {
        type: "LEFT",
        table: "position AS p",
        on: "a.position_id = p.id",
      },
      {
        type: "LEFT",
        table: "type AS t",
        on: "a.type_id = t.id",
      },
      {
        type: "LEFT",
        table: "dept AS d",
        on: "a.dept_id = d.id",
      },
    ],
  },
  // asset: {
  //   label: "ยืม (Software & Hardware)",
  //   table: "borrow AS x",
  //   projection: `x.id, x.user_id, r.name AS asset_name, x.asset_id, x.amount, r.detail, r.type, NULLIF(CONCAT_WS(' ', a.title, a.firstname, a.middlename, a.lastname), '') AS name,
  //   a.position_id, position_name, dept_name, type_name, a.phone, one_signature, two_signature, description,
  //   CASE
  //     WHEN one_status = 'true' THEN 'true'\n
  //     WHEN one_status = 'false' THEN 'false'\n
  //     ELSE NULL
  //   END AS status,
  //   time_start, time_end, one_status, two_status, sucess_time, x.create_at`,
  //   join: [
  //     {
  //       type: "LEFT",
  //       table: "core_role AS a",
  //       on: "x.user_id = a.user_id",
  //     },
  //     {
  //       type: "LEFT",
  //       table: "asset AS r",
  //       on: "x.asset_id = r.id",
  //     },
  //     {
  //       type: "LEFT",
  //       table: "position AS p",
  //       on: "a.position_id = p.id",
  //     },
  //     {
  //       type: "LEFT",
  //       table: "type AS t",
  //       on: "a.type_id = t.id",
  //     },
  //     {
  //       type: "LEFT",
  //       table: "dept AS d",
  //       on: "a.dept_id = d.id",
  //     },
  //   ],
  // },
};

const AdminPage = (props) => {
  const userState = useSelector((state) => state.auth.authUser);

  const [selectState, setSelectState] = useState(props?.mode || "leave");
  const [roomState, setRoomState] = useState([]);
  const [facilityState, setFacilityState] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalPage, setTotalPage] = useState(50);
  const [skipPage, setSkipPage] = useState({
    limit: 50,
    skip: 0,
  });
  const [loading, setLoading] = useState(false);

  const getQuery = async (_rcl, { skip, limit }) => {
    setLoading(true);

    const { table, projection, join } = listType[_rcl];

    let obj = {
      table: table,
      join: join,
      sort: "time_start DESC",
      limit: limit,
      offset: limit * skip,
      projection: projection,
      match: "",
    };

    let count = { projection: "*", table: table };

    // if (_rcl === 'asset') obj.match += ""

    if (props?.userid) obj.match += ` x.user_id = '${props.userid}'`;
    if (props?.userid) count.match = `x.user_id = '${props.userid}'`;

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: obj,
      count: count,
    });

    if (res.data.ok) {
      let { data, total } = res.data.message;
      let num = limit * skip + 1;

      const result = data.map((x) => {
        x.no = num++;
        x.time_start = moment(Number(x.time_start)).format("YYYY/MM/DD HH:mm");
        x.time_end = moment(Number(x.time_end)).format("YYYY/MM/DD HH:mm");
        x.create_at = moment(Number(x.create_at)).format("YYYY-MM-DD");
        x.sucess_time = x.sucess_time
          ? moment(new Date(Number(x.sucess_time)).getTime()).format(
              "YYYY-MM-DD"
            )
          : null;
        x.status =
          x.status === null
            ? "อยู่ระหว่างดำเนินการ"
            : x.status === "true"
            ? "อนุมัติ"
            : "ไม่อนุมัติ";

        return x;
      });

      setDataSource(result);
      setTotalPage(total);
    } else {
      console.log(res.data.message);
    }

    setLoading(false);
  };

  const getAllLeave = async (_time_start, _type_leave_id) => {
    // relex ลามาเเล้ว, ลาครั้งนี้, รวม, type_leave_id = 1
    // leave_name = ป่วย ลามาเเล้ว, ลาครั้งนี้, รวม, type_leave_id = 2
    // leave_name = คลอดบุตร ลามาเเล้ว, ลาครั้งนี้, รวม, type_leave_id = 2
    // leave_name = กิจส่วนตัว last_leave_personal, now_leave_personal, sum_leave_personal,  type_leave_id = 2

    const serverTime = await getData(API_ENDPOINT + "/servertime");
    let year = new Date(serverTime.data).getFullYear();

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: "leave",
        match: `user_id = '${userState.user_id}'\n
        AND one_status = 'true' AND two_status = 'true'
        AND type_leave_id = ${_type_leave_id}\n
        AND ${moment(
          _time_start,
          "YYYY/MM/DD HH:mm"
        ).valueOf()} >=  CAST(time_end AS NUMERIC)\n
        AND EXTRACT(YEAR FROM TO_TIMESTAMP(CAST(time_start AS BIGINT)/1000)) = '${year}'`,
        projection:
          "user_id, leave_name, SUM( ROUND( CAST(scheduled AS NUMERIC) ) ) AS total",
        group: `user_id, leave_name`,
      },
    });

    return res.data.message;
  };

  const onChangeSkip = async (page, row) => {
    let e = { skip: page - 1, limit: row };

    setSkipPage(e);
    await getQuery(selectState, e);
  };

  const handlePdf = async (r) => {
    setLoading(true);

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: "core_role AS x",
        match: `user_id = '${r.one_signature}' OR user_id = '${r.two_signature}' OR user_id = '${r.user_id}'`,
        projection: `user_id, NULLIF(CONCAT_WS(' ', x.title, x.firstname, x.middlename, x.lastname), '') AS fullname_th, 
          NULLIF(CONCAT_WS(' ', x.eng_title, x.eng_firstname, x.eng_middlename, x.eng_lastname), '') AS fullname_en,
          position_name, dept_name, image_signature`,
        join: [
          {
            type: "LEFT",
            table: "position AS p",
            on: "x.position_id = p.id",
          },
          {
            type: "LEFT",
            table: "dept AS d",
            on: "x.dept_id = d.id",
          },
        ],
      },
    });

    if (res.data.ok) {
      const filter = res.data.message.filter((x) => x.user_id === r.user_id);
      const filter1 = res.data.message.filter(
        (x) => x.user_id === r.one_signature
      );
      const filter2 = res.data.message.filter(
        (x) => x.user_id === r.two_signature
      );

      if (selectState === "room") {
        let room = roomState;
        let facility = facilityState;

        if (room.length === 0) {
          room = await handleRoom();
          facility = await handleFacility();
        }

        const filterRoom = room.filter((x) => x.id === r.room_id);
        const findFacility = (filterRoom[0]?.default_f || "")
          .split(",")
          .map(Number);
        // const filterFacility = facility.filter((x) =>
        //   findFacility.includes(x.id)
        // );

        setLoading(false);

        return FormRoom(
          r,
          filter[0],
          filter1[0],
          filter2[0],
          room,
          facility,
          findFacility
        );
      } else if (selectState === "car") {
        setLoading(false);

        return FormCar(r, filter[0], filter1[0], filter2[0]);
      } else if (selectState === "leave") {
        const allLeave = await getAllLeave(r.time_start, r.type_leave_id);
        const lastLeave = await getLastLeaveSick(r.user_id, r.time_start);

        setLoading(false);

        return FormLeave(
          r,
          filter[0],
          filter1[0],
          filter2[0],
          lastLeave,
          allLeave
        );
      } else {
        setLoading(false);

        console.log(r);

        return FormAsset(r, filter[0], filter1[0], filter2[0]);
      }
    } else {
      console.log(res.data.message);
    }

    setLoading(false);
  };

  const handleDelete = async (e) => {
    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/delete`, {
      remove: {
        match: `id = ${e.id}`,
      },
      table: listType[selectState].table,
    });

    if (res.data.ok) {
      await getQuery(selectState, skipPage);

      Swal.fire({
        text: "ลบข้อมูลสำเร็จ !",
        showCloseButton: true,
        icon: "success",
        showConfirmButton: true,
      });
    } else {
      console.log(res.data.message);

      Swal.fire({
        title: "ไม่มีข้อมูลในระบบ !",
        showCloseButton: true,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const getLastLeaveSick = async (_id, _time_start) => {
    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: "leave",
        match: `user_id = '${_id}' AND ${moment(
          _time_start,
          "YYYY/MM/DD HH:mm"
        ).valueOf()} >=  CAST(time_end AS NUMERIC) AND type_leave_id = 2 AND one_status = 'true' AND two_status = 'true'`,
        sort: `time_start DESC`,
        limit: `1`,
      },
    });

    return res.data.message[0];
  };

  const handleFacility = async () => {
    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        projection: "*",
        table: "facility",
      },
    });

    setFacilityState(res.data.message);
    return res.data.message;
  };

  const handleRoom = async () => {
    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        projection: "*",
        sort: "id ASC",
        table: "room",
      },
    });

    setRoomState(res.data.message);
    return res.data.message;
  };

  const handleApprove = async (r, status) => {
    let obj =
      userState.role[selectState] === 1
        ? {
            one_status: status,
            one_signature: userState.user_id,
          }
        : {
            two_status: status,
            two_signature: userState.user_id,
          };

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/update`, {
      update: obj,
      match:
        userState.role[selectState] === 1
          ? `one_status IS NULL AND id = ${r.id}`
          : `two_status IS NULL AND id = ${r.id}`,
      table: listType[selectState].table,
    });

    if (res.data.ok) {
      if (res.data.message[1] === 0) {
        Swal.fire({
          text: "มีผู้ใช้งานท่านอื่นอนุมัติแล้ว !",
          showCloseButton: true,
          icon: "error",
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          text: "บันทึกสำเร็จ !",
          showCloseButton: true,
          icon: "success",
          showConfirmButton: true,
        });

        await getQuery(selectState, skipPage);
      }
    } else {
      console.log(res.data.message);
    }
  };

  useEffect(() => {
    async function init() {
      setSkipPage({ limit: 50, skip: 0 });
      setTotalPage(50);

      await getQuery(selectState, skipPage);

      setColumns([
        {
          title: "No.",
          dataIndex: "no",
          key: "no",
          align: "right",
          sorter: (a, b) => (a.no < b.no ? -1 : 1),
        },
        {
          title: "ชื่อ สกุล",
          dataIndex: "name",
          key: "name",
          align: "center",
          sorter: (a, b) => (a.name < b.name ? -1 : 1),
        },
        {
          title: "เวลาเริ่ม",
          dataIndex: "time_start",
          key: "time_start",
          align: "center",
          render: (text) => moment(text).format("DD/MM/YYYY HH:mm"),
          sorter: (a, b) => (a.time_start < b.time_start ? -1 : 1),
        },
        {
          title: "เวลาสิ้นสุด",
          dataIndex: "time_end",
          key: "time_end",
          align: "center",
          render: (text) => moment(text).format("DD/MM/YYYY HH:mm"),
          sorter: (a, b) => (a.time_end < b.time_end ? -1 : 1),
        },
        {
          title:
            selectState === "room"
              ? "ห้อง"
              : selectState === "leave"
              ? "ประเภทการลา"
              : selectState === "car"
              ? "รถ"
              : "software & hardware",
          dataIndex:
            selectState === "room"
              ? "room_name"
              : selectState === "leave"
              ? "leavename"
              : selectState === "car"
              ? "car_name"
              : "asset_name",
          align: "center",
          key: "type",
          render: (text) =>
            selectState === "leave" ? (
              text === "ป่วย" ? (
                <Tag color="error">{text}</Tag>
              ) : text === "พักผ่อน" ? (
                <Tag color="processing">{text}</Tag>
              ) : (
                <Tag color="warning">{text}</Tag>
              )
            ) : (
              text
            ),
          sorter: (a, b) =>
            a[
              selectState === "room"
                ? "room_name"
                : selectState === "leave"
                ? "leavename"
                : "car_name"
            ] <
            b[
              selectState === "room"
                ? "room_name"
                : selectState === "leave"
                ? "leavename"
                : "car_name"
            ]
              ? -1
              : 1,
        },
        selectState === "room"
          ? {
              title: "ชั้น",
              dataIndex: "floor",
              align: "center",
              key: "floor",
              sorter: (a, b) => (a.floor < b.floor ? -1 : 1),
            }
          : selectState === "car"
          ? {
              title: "จำนวนผู้เดินทาง",
              dataIndex: "people",
              align: "center",
              key: "people",
              sorter: (a, b) => (a.people < b.people ? -1 : 1),
            }
          : selectState === "asset"
          ? {
              title: "ประเภท",
              dataIndex: "type",
              align: "center",
              key: "asset_type",
              render: (text) => {
                return text === "software" ? (
                  <Tag color="warning">{text}</Tag>
                ) : (
                  <Tag color="processing">{text}</Tag>
                );
              },
              sorter: (a, b) => (a.type < b.type ? -1 : 1),
            }
          : null,
        selectState === "room"
          ? {
              title: "จำนวนผู้เข้าประชุม",
              dataIndex: "people",
              align: "right",
              key: "people",
              sorter: (a, b) => (a.people < b.people ? -1 : 1),
            }
          : selectState === "car"
          ? {
              title: "ประเภทสถานที่",
              dataIndex: "travel",
              key: "travel",
              align: "center",
              render: (text) =>
                text === "ภายนอกจังหวัดพิษณุโลก" ? (
                  <Tag color="error">{text}</Tag>
                ) : (
                  <Tag color="processing">{text}</Tag>
                ),
              sorter: (a, b) => (a.travel < b.travel ? -1 : 1),
            }
          : selectState === "asset"
          ? {
              title: "จำนวน",
              dataIndex: "amount",
              key: "amount",
              align: "right",
              sorter: (a, b) => (a.amount < b.amount ? -1 : 1),
            }
          : null,
        {
          title: "สถานะ",
          dataIndex: "status",
          key: "status",
          align: "center",
          render: (text, record) => {
            // const filter = APPROVE_STATUS.filter((x) => x.label === text)[0];

            return (
              // <Tag bordered={false} color={filter.color}>
              //   {filter.label}
              // </Tag>
              <div className="flex justify-center items-center gap-2">
                {record.one_status === "true" ? (
                  <CheckCircleOutlined className="text-2xl text-green-500" />
                ) : record.one_status === "false" ? (
                  <CloseCircleOutlined className="text-2xl text-red-500" />
                ) : (
                  <ClockCircleOutlined className="text-2xl text-blue-500" />
                )}

                {["room", "asset"].includes(
                  selectState
                ) ? null : record.two_status === "true" ? (
                  <CheckCircleOutlined className="text-2xl text-green-500" />
                ) : record.two_status === "false" ? (
                  <CloseCircleOutlined className="text-2xl text-red-500" />
                ) : (
                  <ClockCircleOutlined className="text-2xl text-blue-500" />
                )}
              </div>
            );
          },
          // sorter: (a, b) => (a.status < b.status ? -1 : 1),
        },
        {
          title: "PDF",
          dataIndex: "",
          key: "PDF",
          align: "center",
          render: (text, record) => (
            <Tooltip placement="top" title="ดูเอกสาร">
              <FilePdfOutlined
                onClick={() => handlePdf(record)}
                type="button"
                className="text-red-500 text-2xl"
              />
            </Tooltip>
          ),
        },
        {
          title: "",
          dataIndex: "",
          align: "center",
          render: (text) => {
            function approveBtn() {
              return (
                <div className="flex justify-center items-center gap-2">
                  <Button
                    size="small"
                    type="primary"
                    className="bg-blue-500 w-20"
                    onClick={() => handleApprove(text, true)}
                  >
                    อนุมัติ
                  </Button>

                  <Button
                    size="small"
                    type="primary"
                    className="w-20"
                    danger
                    onClick={() => handleApprove(text, false)}
                  >
                    ไม่อนุมัติ
                  </Button>
                </div>
              );
            }

            if (text.status !== "อยู่ระหว่างดำเนินการ") return null;

            if (props.mode) {
              if (text.one_status === null && text.two_status === null) {
                return (
                  <DeleteOutlined
                    type="button"
                    className="text-red-500 text-2xl"
                    onClick={() =>
                      Swal.fire({
                        title: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
                        text: "กรุณาตรวจสอบข้อมูลก่อนลบ",
                        icon: "warning",
                        confirmButtonColor: "#de1a16",
                        showCancelButton: true,
                        confirmButtonText: "ยืนยัน",
                        cancelButtonText: "ยกเลิก",
                        preConfirm: () => handleDelete(text),
                      })
                    }
                  />
                );
              }

              return null;
            }

            const userRole = userState.role[selectState];
            const isPendingApproval =
              [1, 2].includes(userRole) &&
              ((userRole === 1 && text.one_status === null) ||
                (userRole === 2 && text.two_status === null));

            if (!isPendingApproval) return null;
            if (
              selectState === "asset" &&
              text.type === "hardware" &&
              !isDeanOrRong(userState)
            )
              return null;

            if (
              selectState === "leave" &&
              !(
                (userState.position_name === "คณบดี" &&
                  text.type_name === "บุคลากรสายวิชาการ") ||
                ((userState.position_name === "รองคณบดีฝ่ายบริหาร" ||
                  userState.position_name === "หัวหน้าสำนักงานเลขานุการ") &&
                  text.type_name === "บุคลากรสายสนับสนุน")
              )
            ) {
              return null;
            }

            return approveBtn();
          },
        },
      ]);
    }

    init();
  }, [selectState]);

  const selectOption = () => {
    let keys = Object.keys(listType);

    if (!props?.userid) {
      keys = keys.filter((x) => userState.role[x] > 0);
    }

    const opt = keys.map((x) => ({
      value: x,
      label: listType[x].label,
    }));

    return opt;
  };

  return (
    <>
      <LoadingComponent open={loading} />
      <SelectTableComponent
        selectOption={selectOption()}
        selectState={selectState}
        setSelectState={setSelectState}
        dataSource={dataSource}
        columns={columns}
        totalPage={totalPage}
        skipPage={skipPage}
        setSkipPage={setSkipPage}
        onChangeSkip={onChangeSkip}
      />
    </>
  );
};

export default AdminPage;
