import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import pickImage from "./image";
import { isDeanOrRong } from "../utils";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabun.ttf",
    bold: "THSarabun Bold.ttf",
    italics: "THSarabun Italic.ttf",
    bolditalics: "THSarabun BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

const FormAsset = (r, _x, _y, _z) => {
  let user_signature = _x?.image_signature || null;
  let user_name = _x?.fullname_th || _x?.fullname_en || null;
  let user_position = _x?.position_name || null;

  let one_signature = _y?.image_signature || null;
  let one_name = _y?.fullname_th || _y?.fullname_en || null;
  let one_position = _y?.position_name || null;

  let two_signature = _z?.image_signature || null;
  let two_name = _z?.fullname_th || _z?.fullname_en || null;
  let two_position = _z?.position_name || null;

  let create_Start = new Date(r.create_at);
  let TH_datecreate_date = create_Start.toLocaleDateString("th-TH", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  let date_Start = new Date(r.time_start);
  let TH_dateStart_day = date_Start.toLocaleDateString("th-TH", {
    day: "numeric",
  });
  let TH_dateStart_month = date_Start.toLocaleDateString("th-TH", {
    month: "long",
  });
  let TH_dateStart_year = date_Start.toLocaleDateString("th-TH", {
    year: "numeric",
  });
  let TH_dateStart_time = date_Start.toLocaleDateString("th-TH", {
    hour: "numeric",
    minute: "numeric",
  });
  let Start_year = TH_dateStart_year.substring(TH_dateStart_year.length - 4);

  let Start_time = TH_dateStart_time.substring(TH_dateStart_time.length - 5);

  let date_End = new Date(r.time_end);
  let TH_dateEnd_day = date_End.toLocaleDateString("th-TH", { day: "numeric" });
  let TH_dateEnd_month = date_End.toLocaleDateString("th-TH", {
    month: "long",
  });
  let TH_dateEnd_year = date_End.toLocaleDateString("th-TH", {
    year: "numeric",
  });
  let TH_dateEnd_time = date_End.toLocaleDateString("th-TH", {
    hour: "numeric",
    minute: "numeric",
  });
  let End_year = TH_dateEnd_year.substring(TH_dateEnd_year.length - 4);
  let End_time = TH_dateEnd_time.substring(TH_dateEnd_time.length - 5);

  var dd = {
    info: {
      title: "แบบใบขออนุมัติการขอยืม software & hardware",
    },
    content: [
      { image: "Krut", width: 60, alignment: "left" },
      {
        text: "บันทึกข้อความ",
        style: "header",
        alignment: "center",
        margin: [0, -40, 0, 0],
      },
      {
        margin: [0, 30, 0, 0],
        columnGap: 5,
        columns: [
          {
            text: "ส่วนราชการ",
            alignment: "left",
            style: "bigger",
            width: "auto",
          },
          {
            text: " คณะโลจิสติกส์และดิจิทัลซัพพลายเชน สำนักงานเลขานุการ งาน ............................................ โทร .......................................................",
            alignment: "left",
            margin: [0, 2, 0, 0],
            width: "auto",
          },
        ],
      },
      {
        columnGap: 5,
        columns: [
          {
            text: "ที่",
            alignment: "left",
            width: "auto",
            style: "bigger",
          },
          {
            text: "อว 0603.31.01(.........)/ ...........................",
            width: "auto",
            alignment: "left",
            margin: [0, 2, 0, 0],
          },
          {
            text: "วันที่",
            alignment: "left",
            margin: [5, 0, 0, 0],
            style: "bigger",
            width: "auto",
          },
          {
            text: ` ${r.create_at ? TH_datecreate_date : "-"} `,
            alignment: "left",
            margin: [0, 2, 0, 0],
            width: "auto",
            style: "dotline",
          },
        ],
      },
      {
        text: [
          { text: "เรื่อง ", style: "bigger" },
          {
            text: ` ขออนุมัติใช้ห้องประชุม`,
            margin: [0, 2, 0, 0],
          },
        ],
        alignment: "left",
      },
      {
        text: [
          { text: "เรียน ", style: "bigger" },
          {
            text: isDeanOrRong(_y)
              ? " คณบดีหรือรองคณบดีคณะโลจิสติกส์และดิจิทัลซัพพลายเชน"
              : ` ผู้จัดการ Biz-lab คณะโลจิสติกส์และดิจิทัลซัพพลายเชน`,
            margin: [0, 2, 0, 0],
          },
        ],
        alignment: "left",
        margin: [0, 0, 0, 20],
      },
      {
        text: [
          { text: "\t\t\t\t\t\tข้าพเจ้า ", preserveLeadingSpaces: true },
          {
            text: ` ${r.name} `,
            style: "dotline",
          },
          { text: " ตำแหน่ง " },
          {
            text: ` ${user_position || "-"} `,
            style: "dotline",
          },
          { text: " งาน " },
          {
            text: ` ${_x.dept_name || "-"} `,
            style: "dotline",
          },
          " โทรศัพท์มือถือ/ที่ทำงาน ",
          { text: ` ${r.phone || "-"} `, style: "dotline" },
          " ขอความอนุเคราะห์ยืม software & hardware ",
          { text: r.asset_name, style: "dotline" },
          " รายละเอียด software & hardware ",
          { text: r.detail, style: "dotline" },
          " เพื่อเป็นประโยชน์ใน ",
          { text: r.description, style: "dotline" },
        ],
        alignment: "left",
        margin: [0, 0, 0, 20],
      },
      {
        text: [
          "ในวันที่ ",
          { text: ` ${TH_dateStart_day} `, style: "dotline" },
          " เดือน ",
          { text: ` ${TH_dateStart_month} `, style: "dotline" },
          " พ.ศ. ",
          {
            text: ` ${Start_year} `,
            style: "dotline",
          },
          " เวลา ",
          {
            text: ` ${Start_time} `,
            style: "dotline",
          },
        ],
        alignment: "center",
      },
      {
        text: [
          { text: "ถึงวันที่ " },
          {
            text: ` ${TH_dateEnd_day} `,
            style: "dotline",
          },
          { text: " เดือน " },
          {
            text: ` ${TH_dateEnd_month} `,
            style: "dotline",
          },
          { text: " พ.ศ. " },
          {
            text: ` ${End_year} `,
            style: "dotline",
          },
          { text: " เวลา " },
          {
            text: ` ${End_time} `,
            style: "dotline",
          },
        ],
        alignment: "center",
        margin: [0, 0, 0, 20],
      },
      {
        text: "ทั้งนี้ ผู้ขอใช้ยินดีรับผิดชอบในกรณีที่เกิดความเสียหายต่ออุปกรณ์หรือค่าปฏิบัติงานนอกเวลาราชการ",
        margin: [0, 0, 0, 20],
        alignment: "center",
      },
      {
        margin: [170, 10, 0, 0],
        table: {
          widths: [20, 30, 100],
          headerRows: 1,
          body: [
            [
              {
                image: "one",
                margin: [0, 20, 0, 0],
                width: 15,
                border: [false, false, false, false],
              },
              {
                text: "(ผู้ขอใช้)",
                margin: [0, 20, 0, 0],
                border: [false, false, false, false],
              },
              user_signature
                ? {
                    image: `${user_signature}`,
                    width: 100,
                    height: 30,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                    alignment: "center",
                  }
                : { text: "", border: [false, false, false, false] },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [170, 0, 0, 0],

        table: {
          widths: [30, 125],
          headerRows: 1,
          body: [
            [
              { text: "ตำแหน่ง", border: [false, false, false, false] },
              {
                text: `${!user_position ? "" : user_position}`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, true],
                alignment: "center",
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 70, 0, 0],

        table: {
          widths: [140],
          headerRows: 1,
          body: [
            [
              {
                text: isDeanOrRong(_y)
                  ? "เรียน คณบดีหรือรองคณบดี"
                  : "เรียน ผู้จัดการ Biz-lab คณะโลจิสติกส์และดิจิทัลซัพพลายเชน",
                style: "bigger",
                border: [false, false, false, false],
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [15, 80],
          headerRows: 1,
          body: [
            [
              {
                image: "two",
                width: 15,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: "เพื่อโปรดพิจารณาอนุมัติ",
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 10, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [{ text: "", border: [false, false, false, false] }],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              two_signature
                ? {
                    image: `${two_signature}`,
                    width: 100,
                    height: 30,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                    alignment: "center",
                  }
                : { text: "", border: [false, false, false, false] },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `(${!two_name ? "" : two_name})`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `${!two_position ? "" : two_position}`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [300, -110, 0, 0],

        table: {
          widths: [15, 25, 15, 20, 15, 30],
          headerRows: 1,
          body: [
            [
              {
                image: "three",
                width: 15,
                margin: [0, -20, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: "คำสั่ง",
                style: "bigger",
                margin: [0, -20, 0, 0],
                border: [false, false, false, false],
              },
              {
                image: r.status === "อนุมัติ" ? "checKedCicle" : "cicle",
                width: 10,
                border: [false, false, false, false],
              },
              {
                text: "อนุมัติ",
                border: [false, false, false, false],
              },
              {
                image: r.status === "ไม่อนุมัติ" ? "checKedCicle" : "cicle",
                width: 10,
                border: [false, false, false, false],
              },
              {
                text: "ไม่อนุมัติ",
                border: [false, false, false, false],
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [340, 0, 0, 0],

        table: {
          widths: [25, 100],
          headerRows: 1,
          body: [
            [
              {
                text: "(ลงชื่อ)",
                margin: [0, 25, 0, 0],
                border: [false, false, false, false],
              },
              one_signature
                ? {
                    image: `${one_signature}`,
                    width: 100,
                    height: 30,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                    alignment: "center",
                  }
                : { text: "", border: [false, false, false, false] },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [370, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `(${!one_name ? "" : one_name})`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [340, 0, 0, 0],

        table: {
          widths: [150],
          headerRows: 1,
          body: [
            [
              {
                text: isDeanOrRong(_y)
                  ? "ตำแหน่ง คณบดีหรือรองคณบดีคณะโลจิสติกส์และดิจิทัลซัพพลายเชน"
                  : `ตำแหน่ง ผู้จัดการ Biz-lab คณะโลจิสติกส์และดิจิทัลซัพพลายเชน`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      bigger: {
        fontSize: 15,
        bold: true,
      },
      dotline: {
        decoration: "underline",
        decorationStyle: "dotted",
      },
    },
    defaultStyle: {
      columnGap: 20,
      font: "THSarabunNew",
    },
    images: {
      checKedCicle: pickImage.checKedCicle,
      three: pickImage.three,
      two: pickImage.two,
      one: pickImage.one,
      checKed: pickImage.checKed,
      unchecKed: pickImage.unchecKed,
      cicle: pickImage.cicle,
      Krut: pickImage.Krut,
    },
  };

  pdfMake.createPdf(dd).open();
};

export { FormAsset };
