import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Form,
  Card,
  Menu,
  Table,
  Button,
  Input,
  Modal,
  Tag,
  Select,
  Switch,
  Divider,
  Alert,
  List,
  Row,
  Col,
} from "antd";
import { librarian, meeting, leasing, box, setting } from "../image";
import { API_ENDPOINT, PREFIX_NAME } from "../config";
import LoadingComponent from "../components/loading";
import { getData, postData } from "../utils";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const items = [
  {
    label: "ตั้งค่าผู้ใช้",
    key: "system",
    icon: <img src={setting} className="w-6" />,
    children: [
      {
        label: "ผู้ใช้งานระบบ",
        key: "user",
      },
      {
        label: "ผู้มีสิทธิ์อนุมัติ",
        key: "approve",
      },
    ],
  },
  {
    label: "การจองห้อง",
    key: "room",
    icon: <img src={meeting} className="w-6" />,
    disabled: true,
  },
  {
    label: "การจองรถ",
    key: "car",
    icon: <img src={leasing} className="w-6" />,
    disabled: true,
  },
  {
    label: "การลา",
    key: "leave",
    icon: <img src={box} className="w-6" />,
    disabled: true,
  },
  {
    label: "การยืม",
    key: "borrow",
    icon: <img src={librarian} className="w-6" />,
    children: [
      {
        label: "Software & Hardware",
        key: "asset",
      },
    ],
  },
];

const SettingPage = () => {
  const userState = useSelector((state) => state.auth.authUser);

  const [formAsset] = Form.useForm();

  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentMenu, setCurrentMenu] = useState("user");
  const [pathMenu, setPathMenu] = useState(["system", "user"]);
  const [position, setPosition] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [skipPage, setSkipPage] = useState({
    limit: 50,
    skip: 0,
  });
  const [modalContent, setModalContent] = useState({
    open: false,
    title: "",
  });

  const getQuery = async (_key, { skip, limit }) => {
    setLoading(true);

    try {
      if (_key === "asset") {
        const res = await postData(
          API_ENDPOINT + `/db/mis_logistic_nu/select`,
          {
            query: {
              table: _key,
              // match: "deactive IS NULL OR deactive=false",
              sort: "create_at ASC",
              limit: limit,
              offset: limit * skip,
              projection: "*",
            },
            count: {
              match: "deactive IS NULL OR deactive=false",
              projection: "*",
              table: _key,
            },
          }
        );

        if (res.data.ok) {
          let { data, total } = res.data.message;
          let num = limit * skip + 1;

          const result = data.map((x) => {
            x.no = num++;

            return x;
          });

          setDataSource(result);
          setTotalPage(total);
        } else {
          console.log(res.data.message);
        }
      } else if (_key === "user") {
        const res = await postData(
          API_ENDPOINT + `/db/mis_logistic_nu/select`,
          {
            query: {
              table: "core_role AS x",
              join: [
                {
                  type: "LEFT",
                  table: "position AS p",
                  on: "x.position_id = p.id",
                },
                {
                  type: "LEFT",
                  table: "management_position AS mp",
                  on: "x.management_position_id = mp.id",
                },
                {
                  type: "LEFT",
                  table: "type AS t",
                  on: "x.type_id = t.id",
                },
              ],
              projection: `x.user_id, x.user_name, NULLIF(
              CONCAT_WS(' ', 
                COALESCE(x.title, ''), 
                COALESCE(NULLIF(
                  CONCAT_WS(' ', x.firstname, x.middlename, x.lastname), ''), 
                  CONCAT_WS(' ', eng_firstname, eng_middlename, eng_lastname)
                )
              ), '') AS name,
              x.title, x.firstname, x.middlename, x.lastname,
              eng_title, eng_firstname, eng_middlename, eng_lastname,
              x.management_position_id, mp.management_position_name, deactive,
              x.position_id, position_name, x.role , type_name, x.type_id`,
              sort: "firstname ASC",
              limit: limit,
              offset: limit * skip,
            },
            count: {
              match: "deactive IS NULL OR deactive=false",
              table: "core_role",
            },
          }
        );

        if (res.data.ok) {
          let { data, total } = res.data.message;
          let num = limit * skip + 1;

          const result = data.map((x) => {
            x.no = num++;

            return x;
          });

          setDataSource(result);
          setTotalPage(total);

          const res2 = await postData(
            API_ENDPOINT + `/db/mis_logistic_nu/select`,
            {
              query: { projection: `*`, table: "position" },
            }
          );

          const res3 = await postData(
            API_ENDPOINT + `/db/mis_logistic_nu/select`,
            {
              query: {
                projection: `*`,
                table: "management_position",
              },
            }
          );

          if (res2.data.ok && res3.data.ok) {
            setPosition({
              position: res2.data.message,
              management_position: res3.data.message,
            });
          } else {
            console.log(res2.data.message);
            console.log(res3.data.message);
          }
        } else {
          console.log(res.data.message);
        }
      } else if (_key === "approve") {
        const res = await postData(
          API_ENDPOINT + `/db/mis_logistic_nu/select`,
          {
            query: {
              table: "core_role AS x",
              join: [
                {
                  type: "LEFT",
                  table: "position AS p",
                  on: "x.position_id = p.id",
                },
                {
                  type: "LEFT",
                  table: "management_position AS mp",
                  on: "x.management_position_id = mp.id",
                },
                {
                  type: "LEFT",
                  table: "type AS t",
                  on: "x.type_id = t.id",
                },
              ],
              projection: `x.user_id, NULLIF(
              CONCAT_WS(' ', 
                COALESCE(x.title, ''), 
                COALESCE(NULLIF(
                  CONCAT_WS(' ', x.firstname, x.middlename, x.lastname), ''), 
                  CONCAT_WS(' ', eng_firstname, eng_middlename, eng_lastname)
                )
              ), '') AS name,
              x.management_position_id, mp.management_position_name,
              x.position_id, position_name, x.role , type_name, x.type_id`,
              sort: "firstname ASC",
              limit: limit,
              offset: limit * skip,
            },
          }
        );

        if (res.data.ok) {
          let data = res.data.message;
          let num = limit * skip + 1;

          const result = data.map((x) => {
            x.no = num++;

            return x;
          });

          setDataSource([
            {
              no: 1,
              system: "room",
              users_readonly: result,
              users_approve: result,
              users_own: result,
            },
            {
              no: 2,
              system: "car",
              users_readonly: result,
              users_approve: result,
              users_own: result,
            },
            {
              no: 3,
              system: "leave",
              users_readonly: result,
              users_approve: result,
              users_own: result,
            },
          ]);
        } else {
          console.log(res.data.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }

    setLoading(false);
  };

  const getQueryBorrow = async () => {
    setLoading(true);

    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let dupMatch = `(CAST(time_start AS BIGINT) >= ${serverTime.data} OR CAST(time_end AS BIGINT) >= ${serverTime.data})\n`;

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: "borrow",
        match: `(${dupMatch}) AND one_status = 'true'`,
        projection: "asset_id, COUNT(asset_id) AS total",
        group: `asset_id`,
      },
    });

    setLoading(false);

    if (res.data.ok) {
      return res.data.message;
    } else {
      console.log(res.data.message);
      return [];
    }
  };

  const onChangeSkip = async (page, row) => {
    let e = { skip: page - 1, limit: row };
    let tb = findCurrentMenu().key;

    setSkipPage(e);
    await getQuery(tb, e);
  };

  const handleDeactive = async (e, _status) => {
    setLoading(true);
    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let tb = findCurrentMenu().key;

    const res = await postData(API_ENDPOINT + "/db/mis_logistic_nu/update", {
      update: {
        deactive: _status,
        update_at: serverTime.data,
        user_id_update: userState.user_id,
      },
      table: tb,
      match: `id = '${e.id}'`,
    });

    if (res.data.ok) {
      await getQuery(tb, skipPage);

      Swal.fire({
        text: "บันทึกสำเร็จ !",
        showCloseButton: true,
        icon: "success",
        showConfirmButton: true,
      });
    } else {
      console.log(res.data.message);
    }

    setLoading(false);
  };

  const handleDeactiveUser = async (e, _status) => {
    setLoading(true);
    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let tb = findCurrentMenu().key;

    const res = await postData(API_ENDPOINT + "/db/mis_logistic_nu/update", {
      update: {
        deactive: _status,
        update_at: serverTime.data,
      },
      table: "core_role",
      match: `user_id = '${e.id}'`,
    });

    if (res.data.ok) {
      await getQuery(tb, skipPage);

      Swal.fire({
        text: "บันทึกสำเร็จ !",
        showCloseButton: true,
        icon: "success",
        showConfirmButton: true,
      });
    } else {
      console.log(res.data.message);
    }

    setLoading(false);
  };

  function findDifferences(oldObj, newObj) {
    const result = {};

    Object.keys(oldObj).forEach((key) => {
      const oldSet = new Set(oldObj[key]);
      const newSet = new Set(newObj[key]);

      const added = [...newSet].filter((item) => !oldSet.has(item));

      const removed = [...oldSet].filter((item) => !newSet.has(item));

      result[key] = { added, removed };
    });

    return result;
  }

  const handleSave = async (e) => {
    setLoading(true);
    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let tb = findCurrentMenu().key;
    let obj = formAsset.getFieldsValue();

    if (tb === "asset") {
      if (modalContent.id) {
        obj["user_id_update"] = userState.user_id;
        obj["update_at"] = serverTime.data;
      } else {
        obj["user_id_create"] = userState.user_id;
        obj["create_at"] = serverTime.data;
      }

      const res = modalContent.id
        ? await postData(API_ENDPOINT + "/db/mis_logistic_nu/update", {
            update: obj,
            table: tb,
            match: `id = '${modalContent.id}'`,
          })
        : await postData(API_ENDPOINT + "/db/mis_logistic_nu/insert", {
            insert: obj,
            table: tb,
          });

      if (res.data.ok) {
        await getQuery(tb, skipPage);

        setDefaultForm();

        Swal.fire({
          text: "บันทึกสำเร็จ !",
          showCloseButton: true,
          icon: "success",
          showConfirmButton: true,
        });
      } else {
        console.log(res.data.message);
      }
    } else if (tb === "user") {
      let data = {
        title: obj.title,
        firstname: obj.firstname,
        lastname: obj.lastname,
        middlename: obj.middlename,
      };

      if (obj.type === "2") {
        data["management_position_id"] = obj.position;
      } else {
        data["position_id"] = obj.position;
      }

      let res;

      if (modalContent.id) {
        res = await postData(API_ENDPOINT + "/db/mis_logistic_nu/update", {
          update: data,
          table: "core_role",
          match: `user_id = '${modalContent.id}'`,
        });
      } else {
        res = await postData(API_ENDPOINT + `/auth/register`, {
          username: obj.user_name,
          password: "1234qwer",
          ...data,
        });
      }

      if (res.data.ok) {
        await getQuery(tb, skipPage);

        setDefaultForm();

        Swal.fire({
          text: "บันทึกสำเร็จ !",
          showCloseButton: true,
          icon: "success",
          showConfirmButton: true,
        });
      } else {
        console.log(res.data.message);
      }
    } else if (tb === "approve") {
      let readonly = modalContent.users_readonly
        .filter((f) => f.role)
        .map((x) => ({
          user_id: x.user_id,
          role: x.role,
        }))
        .filter((f) => f.role[modalContent.id] === 3)
        .map((m) => m.user_id);
      let approve = modalContent.users_approve
        .filter((f) => f.role)
        .map((x) => ({
          user_id: x.user_id,
          role: x.role,
        }))
        .filter((f) => f.role[modalContent.id] === 1)
        .map((m) => m.user_id);
      let own = modalContent.users_own
        .filter((f) => f.role)
        .map((x) => ({
          user_id: x.user_id,
          role: x.role,
        }))
        .filter((f) => f.role[modalContent.id] === 2)
        .map((m) => m.user_id);

      let old = {
        readonly: readonly,
        approve: approve,
        own: own,
      };
      let dif = findDifferences(old, obj);

      let keysRole = ["approve", "own", "readonly"];

      for (let index = 0; index < keysRole.length; index++) {
        for (let i = 0; i < dif[keysRole[index]]["added"].length; i++) {
          let added = dif[keysRole[index]]["added"][i];

          const res = await postData(
            API_ENDPOINT + "/db/mis_logistic_nu/update",
            {
              json: {
                update: {
                  role: { [modalContent.id]: index + 1 },
                },
              },
              table: "core_role",
              match: `user_id = '${added}'`,
            }
          );

          console.log(res.data.message);
        }

        for (let i = 0; i < dif[keysRole[index]]["removed"].length; i++) {
          let removed = dif[keysRole[index]]["removed"][i];

          const res = await postData(
            API_ENDPOINT + "/db/mis_logistic_nu/update",
            {
              json: {
                remove: {
                  role: [modalContent.id],
                },
              },
              table: "core_role",
              match: `user_id = '${removed}'`,
            }
          );

          console.log(res.data.message);
        }
      }

      await getQuery(tb, skipPage);

      setDefaultForm();

      Swal.fire({
        text: "บันทึกสำเร็จ !",
        showCloseButton: true,
        icon: "success",
        showConfirmButton: true,
      });
    }

    setLoading(false);
    setModalContent({ open: false });
  };

  const findCurrentMenu = () => {
    let cur = items;

    pathMenu.forEach((x) => {
      const filter = cur.filter((y) => y.key === x);

      if (filter[0].children) {
        cur = filter[0].children;
      } else {
        cur = filter[0];
      }
    });

    return cur || {};
  };

  const setDefaultForm = () => {
    let tb = findCurrentMenu().key;

    if (tb === "asset") {
      formAsset.setFieldsValue({
        name: "",
        detail: "",
        type: "",
        total: 1,
      });
    } else if (tb === "user") {
      formAsset.resetFields();
    }
  };

  useEffect(() => {
    async function init() {
      setSkipPage({ limit: 50, skip: 0 });
      setTotalPage(50);

      let tb = findCurrentMenu().key;

      await getQuery(tb, { limit: 50, skip: 0 });
      const dataBorrow = await getQueryBorrow();

      if (tb === "asset") {
        setColumns([
          {
            title: "No.",
            dataIndex: "no",
            key: "no",
            align: "right",
            sorter: (a, b) => (a.no < b.no ? -1 : 1),
          },
          {
            title: "ชื่อ",
            dataIndex: "name",
            key: "name",
            align: "left",
            //   render: (text) => (
            //     <Input.TextArea defaultValue={text} autoSize={{ minRows: 1 }} />
            //   ),
            sorter: (a, b) => (a.name < b.name ? -1 : 1),
          },
          {
            title: "คำอธิบาย",
            dataIndex: "detail",
            key: "detail",
            align: "left",
            sorter: (a, b) => (a.detail < b.detail ? -1 : 1),
          },
          {
            title: "ประเภท",
            dataIndex: "type",
            key: "type",
            align: "center",
            render: (text) => {
              return text === "software" ? (
                <Tag color="warning">{text}</Tag>
              ) : (
                <Tag color="processing">{text}</Tag>
              );
            },
            sorter: (a, b) => (a.type < b.type ? -1 : 1),
          },
          {
            title: "จำนวน (ยืม/ทั้งหมด)",
            dataIndex: "total",
            key: "total",
            align: "right",
            render: (text, record) => {
              const fineBorrow = dataBorrow.find(
                (x) => x.asset_id === record.id
              );

              return (
                <div>
                  {fineBorrow?.total || 0}/{text}
                </div>
              );
            },
            sorter: (a, b) => (a.total < b.total ? -1 : 1),
          },
          {
            title: "",
            dataIndex: "deactive",
            key: "deactive",
            align: "center",
            render: (text, record) => {
              return (
                <div className="flex gap-7 p-2 justify-center">
                  {text ? (
                    <Switch
                      className="bg-gray-500"
                      checkedChildren="active"
                      unCheckedChildren="deactive"
                      defaultChecked={false}
                      onChange={() => handleDeactive(record, false)}
                    />
                  ) : (
                    <>
                      <EditOutlined
                        className="text-orange-500 cursor-pointer text-2xl"
                        onClick={() => {
                          formAsset.setFieldsValue({ ...record });
                          setModalContent({
                            open: true,
                            title: "Software & Hardware",
                            id: record.id,
                          });
                        }}
                      />
                      <DeleteOutlined
                        className="text-red-500 cursor-pointer text-2xl"
                        onClick={() =>
                          Swal.fire({
                            title: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
                            text: "กรุณาตรวจสอบข้อมูลก่อนลบ",
                            icon: "warning",
                            confirmButtonColor: "#de1a16",
                            showCancelButton: true,
                            confirmButtonText: "ยืนยัน",
                            cancelButtonText: "ยกเลิก",
                            preConfirm: () => handleDeactive(record, true),
                          })
                        }
                      />
                    </>
                  )}
                </div>
              );
            },
          },
        ]);
      } else if (tb === "user") {
        setColumns([
          {
            title: "No.",
            dataIndex: "no",
            key: "no",
            align: "right",
            sorter: (a, b) => (a.no < b.no ? -1 : 1),
          },
          {
            title: "ชื่อ สกุล",
            dataIndex: "name",
            key: "name",
            align: "left",
            sorter: (a, b) => (a.name < b.name ? -1 : 1),
          },
          {
            title: "ประเภทตำแหน่งงาน",
            dataIndex: "management_position_name",
            key: "management_position_name",
            align: "center",
            render: (text) => {
              return text ? (
                <Tag color="processing">ตำแหน่งงานบริหาร</Tag>
              ) : (
                <Tag color="warning">ตำแหน่งงานวิชาการ</Tag>
              );
            },
            // sorter: (a, b) => (a.type_name < b.type_name ? -1 : 1),
          },
          {
            title: "ตำแหน่งงาน",
            dataIndex: "management_position_name",
            key: "management_position_name",
            align: "left",
            render: (text, record) => {
              if (text) {
                return text;
              } else {
                return record.position_name;
              }
            },
          },
          // {
          //   title: "ประเภทบุคคลากร",
          //   dataIndex: "type_name",
          //   key: "type_name",
          //   align: "center",
          //   render: (text) => {
          //     return text === "บุคลากรสายสนับสนุน" ? (
          //       <Tag color="warning">{text}</Tag>
          //     ) : (
          //       <Tag color="processing">{text}</Tag>
          //     );
          //   },
          //   sorter: (a, b) => (a.type_name < b.type_name ? -1 : 1),
          // },
          // {
          //   title: "อนุมัติ",
          //   dataIndex: "role",
          //   key: "role",
          //   align: "center",
          //   render: (text) => {
          //     try {
          //       let t = JSON.parse(text);

          //       return Object.keys(t).map((x) => {
          //         if (x === "room") {
          //           return <Tag color="processing">ห้อง</Tag>;
          //         } else if (x === "car") {
          //           return <Tag color="green">รถ</Tag>;
          //         } else if (x === "leave") {
          //           return <Tag color="warning">ใบลา</Tag>;
          //         }
          //       });
          //     } catch (error) {
          //       return null;
          //     }
          //   },
          // },
          {
            title: "สถานะ",
            dataIndex: "deactive",
            key: "deactive",
            align: "center",
            render: (text, record) => {
              return (
                <div className="flex gap-7 p-2 justify-center">
                  {text ? (
                    <Switch
                      className="bg-gray-500"
                      checkedChildren="active"
                      unCheckedChildren="deactive"
                      defaultChecked={false}
                      onChange={() =>
                        handleDeactiveUser({ id: record.user_id }, false)
                      }
                    />
                  ) : (
                    <>
                      <EditOutlined
                        className="text-orange-500 cursor-pointer text-2xl"
                        onClick={() => {
                          formAsset.setFieldsValue({
                            ...record,
                            type: record.management_position_id ? "2" : "1",
                            position: record.management_position_id
                              ? record.management_position_id
                              : record.position_id,
                          });
                          setModalContent({
                            open: true,
                            title: "ผู้ใช้งานระบบ",
                            id: record.user_id,
                            position: record.management_position_id
                              ? "management_position"
                              : "position",
                          });
                        }}
                      />
                      <DeleteOutlined
                        className="text-red-500 cursor-pointer text-2xl"
                        onClick={() =>
                          Swal.fire({
                            title: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
                            text: "กรุณาตรวจสอบข้อมูลก่อนลบ",
                            icon: "warning",
                            confirmButtonColor: "#de1a16",
                            showCancelButton: true,
                            confirmButtonText: "ยืนยัน",
                            cancelButtonText: "ยกเลิก",
                            preConfirm: () =>
                              handleDeactiveUser({ id: record.user_id }, true),
                          })
                        }
                      />
                    </>
                  )}
                </div>
              );
            },
          },
        ]);
      } else if (tb === "approve") {
        setColumns([
          {
            title: "No.",
            dataIndex: "no",
            key: "no",
            align: "right",
            width: "1%",
            sorter: (a, b) => (a.no < b.no ? -1 : 1),
          },
          {
            title: "ระบบ",
            dataIndex: "system",
            key: "system",
            align: "left",
            width: "10%",
            render: (text, record) =>
              ({
                room: "การจองห้อง",
                car: "การจองรถ",
                leave: "การลา",
              }[text]),
            sorter: (a, b) => (a.name < b.name ? -1 : 1),
          },
          {
            title: "ดูได้อย่างเดียว",
            dataIndex: "users_readonly",
            key: "users_readonly",
            align: "center",
            width: "32%",
            render: (text, record) => {
              let sit = (text || [])
                .filter((f) => f.role)
                .map((x) => ({
                  ...x,
                }))
                .filter((f) => f.role[record.system] === 3);

              return (
                <List
                  dataSource={sit}
                  renderItem={(x, i) => (
                    <List.Item key={i}>
                      <div className="flex gap-2 items-center justify-between">
                        <label>{x.name}</label>
                        {x.management_position_id ? (
                          <Tag color="processing" className="h-fit">
                            {x.management_position_name}
                          </Tag>
                        ) : (
                          <Tag color="warning" className="h-fit">
                            {x.position_name}
                          </Tag>
                        )}
                      </div>
                    </List.Item>
                  )}
                />
              );
            },
          },
          {
            title: "อนุมัติ",
            dataIndex: "users_approve",
            key: "users_approve",
            align: "center",
            width: "32%",
            render: (text, record) => {
              let sit = (text || [])
                .filter((f) => f.role)
                .map((x) => ({
                  ...x,
                }))
                .filter((f) => f.role[record.system] === 1);

              return (
                <List
                  dataSource={sit}
                  renderItem={(x, i) => (
                    <List.Item key={i}>
                      <div className="flex gap-2 items-center justify-between">
                        <label>{x.name}</label>
                        {x.management_position_id ? (
                          <Tag color="processing" className="h-fit">
                            {x.management_position_name}
                          </Tag>
                        ) : (
                          <Tag color="warning" className="h-fit">
                            {x.position_name}
                          </Tag>
                        )}
                      </div>
                    </List.Item>
                  )}
                />
              );
            },
          },
          {
            title: "ออกคำสั่ง",
            dataIndex: "users_own",
            key: "users_own",
            align: "center",
            width: "32%",
            render: (text, record) => {
              let sit = (text || [])
                .filter((f) => f.role)
                .map((x) => ({
                  ...x,
                }))
                .filter((f) => f.role[record.system] === 2);

              return (
                <List
                  dataSource={sit}
                  renderItem={(x, i) => (
                    <List.Item key={i}>
                      <div className="flex gap-2 items-center justify-between">
                        <label>{x.name}</label>
                        {x.management_position_id ? (
                          <Tag color="processing" className="h-fit">
                            {x.management_position_name}
                          </Tag>
                        ) : (
                          <Tag color="warning" className="h-fit">
                            {x.position_name}
                          </Tag>
                        )}
                      </div>
                    </List.Item>
                  )}
                />
              );
            },
          },
          {
            title: "แก้ไข",
            dataIndex: "system",
            key: "system",
            align: "center",
            render: (text, record) => {
              return (
                <div className="flex gap-7 p-2 justify-center">
                  <EditOutlined
                    className="text-orange-500 cursor-pointer text-2xl"
                    onClick={() => {
                      formAsset.setFieldsValue({
                        readonly: record.users_readonly
                          .filter((f) => f.role)
                          .map((x) => ({
                            user_id: x.user_id,
                            role: x.role,
                          }))
                          .filter((f) => f.role[text] === 3)
                          .map((m) => m.user_id),
                        approve: record.users_approve
                          .filter((f) => f.role)
                          .map((x) => ({
                            user_id: x.user_id,
                            role: x.role,
                          }))
                          .filter((f) => f.role[text] === 1)
                          .map((m) => m.user_id),
                        own: record.users_own
                          .filter((f) => f.role)
                          .map((x) => ({
                            user_id: x.user_id,
                            role: x.role,
                          }))
                          .filter((f) => f.role[text] === 2)
                          .map((m) => m.user_id),
                      });
                      setModalContent({
                        open: true,
                        title:
                          "แก้ไขสิทธิ์" +
                          {
                            room: "การจองห้อง",
                            car: "การจองรถ",
                            leave: "การลา",
                          }[text],
                        id: text,
                        users_readonly: record.users_readonly,
                        users_approve: record.users_approve,
                        users_own: record.users_own,
                      });
                    }}
                  />
                </div>
              );
            },
          },
        ]);
      }
    }

    init();
  }, [currentMenu]);

  return (
    <>
      <LoadingComponent open={loading} />
      <div className="w-full h-full flex flex-col gap-4">
        <Menu
          onClick={(e) => {
            setCurrentMenu(e.key);
            setPathMenu(e.keyPath.reverse());
          }}
          selectedKeys={[currentMenu]}
          mode="horizontal"
          items={items}
        />

        <Card
          type="inner"
          title={findCurrentMenu().label}
          extra={
            currentMenu !== "approve" && (
              <Button
                type="primary"
                className="bg-blue-500 flex justify-center items-center"
                onClick={() => {
                  setDefaultForm();
                  setModalContent({
                    open: true,
                    title: findCurrentMenu().label,
                  });

                  // formAsset.setFieldsValue({
                  //   ...record,
                  //   type: record.management_position_id ? "2" : "1",
                  //   position: record.management_position_id
                  //     ? record.management_position_id
                  //     : record.position_id,
                  // });
                  // setModalContent({
                  //   open: true,
                  //   title: "ผู้ใช้งานระบบ",
                  //   id: record.user_id,
                  //   position: record.management_position_id
                  //     ? "management_position"
                  //     : "position",
                  // });
                }}
              >
                <PlusCircleOutlined />
                เพิ่ม
              </Button>
            )
          }
          headStyle={{ background: "#ccc" }}
          style={{
            boxShadow:
              "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
          }}
          className="w-full"
        >
          <Table
            columns={columns.filter((x) => x)}
            dataSource={dataSource}
            className={
              "w-full rounded overflow-y-hidden overflow-x-scroll scrollbar-hide shadow-lg"
            }
            bordered
            pagination={{
              size: "small",
              total: totalPage,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              current: skipPage.skip + 1,
              pageSize: skipPage.limit,
              className: "px-4",
              onChange: (page, row) => onChangeSkip(page, row),
            }}
            locale={{
              triggerDesc: null,
              triggerAsc: null,
              cancelSort: null,
            }}
          />

          {/* {currentMenu === "approve" && (
            <>
              <Divider />
              <Alert
                message="หมายเหตุ"
                description={
                  <div className="flex flex-col gap-2">
                    <>
                      <p>
                        * คณะฯ เปิดให้บริการห้องแก่บุคลากรภายนอก เฉพาะห้อง 201
                        202 และ 301 เท่านั้นส่วนห้องอื่นๆ
                        จะให้บริการสำหรับจัดการเรียนการสอนของคณะฯ *
                      </p>
                      <p>
                        ** ทั้งนี้
                        ผู้ขอใช้ยินดีรับผิดชอบในกรณีที่เกิดความเสียหายต่ออุปกรณ์หรือค่าปฏิบัติงานนอกเวลาราชการ
                        **
                      </p>
                    </>
                  </div>
                }
                type="warning"
                showIcon
              />
            </>
          )} */}
        </Card>
      </div>

      <Modal
        title={modalContent.title}
        centered
        open={modalContent.open}
        // onOk={() => setOpen(false)}
        onCancel={() => setModalContent({ open: false })}
        width={1000}
        footer={null}
      >
        <Form layout={"vertical"} form={formAsset} onFinish={handleSave}>
          <Card
            bordered={true}
            type="inner"
            style={{
              boxShadow:
                "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
            }}
          >
            {currentMenu === "asset" && (
              <>
                <Form.Item
                  label={`ชื่อ`}
                  name={"name"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Input.TextArea
                    placeholder={"ชื่อ"}
                    autoSize={{ minRows: 1 }}
                  />
                </Form.Item>
                <Form.Item
                  label={`คำอธิบาย`}
                  name={"detail"}
                  //   rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Input.TextArea
                    placeholder={"คำอธิบาย"}
                    autoSize={{ minRows: 4 }}
                  />
                </Form.Item>
                <Form.Item
                  label={`ประเภท`}
                  name={"type"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Select
                    options={[
                      {
                        value: "",
                        label: "กรุณาเลือก",
                        disabled: true,
                      },
                      {
                        value: "software",
                        label: "software",
                      },
                      {
                        value: "hardware",
                        label: "hardware",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label={`จำนวน`}
                  name={"total"}
                  rules={[
                    { required: true, message: "กรุณากรอก ${label}!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (Number(value) < 0) {
                          return Promise.reject(
                            new Error(`กรุณากรอกจำนวน ให้ถูกต้อง!`)
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input type="number" defaultValue="1" min={1} step={1} />
                </Form.Item>
              </>
            )}

            {currentMenu === "user" && (
              <>
                <Form.Item
                  label="ชื่อผู้ใช้"
                  name="user_name"
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Input placeholder="ชื่อผู้ใช้" disabled={modalContent.id} />
                </Form.Item>
                <Row>
                  <Col span={3} className="p-2 pl-0">
                    <Form.Item
                      label="คำนำหน้า"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก ชื่อ!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue=""
                        options={[
                          {
                            value: "",
                            label: "กรุณาเลือก",
                            disabled: true,
                          },
                          ...PREFIX_NAME.th,
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="p-2">
                    <Form.Item
                      label="ชื่อ"
                      name="firstname"
                      rules={[
                        { required: true, message: "กรุณากรอก ${label}!" },
                      ]}
                    >
                      <Input placeholder="ชื่อ" />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="p-2">
                    <Form.Item label="ชื่อกลาง" name="middlename">
                      <Input placeholder="ชื่อกลาง" defaultValue="" />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="p-2 pr-0">
                    <Form.Item
                      label="นามสกุล"
                      name="lastname"
                      rules={[
                        { required: true, message: "กรุณากรอก ${label}!" },
                      ]}
                    >
                      <Input placeholder="นามสกุล" />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Form.Item
                  label={`ชื่อ`}
                  name={"name"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Input.TextArea
                    placeholder={"ชื่อ"}
                    autoSize={{ minRows: 1 }}
                    disabled
                  />
                </Form.Item> */}
                <Form.Item
                  label={`ประเภทตำแหน่งงาน`}
                  name={"type"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Select
                    options={[
                      {
                        value: "",
                        label: "กรุณาเลือก",
                        disabled: true,
                      },
                      {
                        value: "1",
                        label: "1. ตำแหน่งงานวิชาการ",
                      },
                      {
                        value: "2",
                        label: "2. ตำแหน่งงานบริหาร",
                      },
                    ]}
                    onChange={(e) => {
                      formAsset.setFieldValue("position", "");
                      setModalContent((prev) => ({
                        ...prev,
                        position:
                          e === "2" ? "management_position" : "position",
                      }));
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={`ตำแหน่งงาน`}
                  name={"position"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Select
                    options={[
                      {
                        key: 0,
                        value: "",
                        label: "กรุณาเลือก",
                        disabled: true,
                      },
                      ...(position[modalContent.position] || []).map(
                        (x, i) => ({
                          key: i + 1,
                          value: x.id,
                          label:
                            i + 1 + ". " + x[modalContent.position + "_name"],
                        })
                      ),
                    ]}
                  />
                </Form.Item>
              </>
            )}

            {currentMenu === "approve" && (
              <>
                <Form.Item
                  label={`ดูได้อย่างเดียว`}
                  name={"readonly"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    className="w-full"
                    options={(modalContent.users_readonly || []).map(
                      (x, i) => ({
                        key: i,
                        value: x.user_id,
                        label: (
                          <div className="flex gap-2 items-center justify-between">
                            <label>
                              {i + 1}. {x.name}
                            </label>
                            {x.management_position_id ? (
                              <Tag color="processing" className="h-fit">
                                {x.management_position_name}
                              </Tag>
                            ) : (
                              <Tag color="warning" className="h-fit">
                                {x.position_name}
                              </Tag>
                            )}
                          </div>
                        ),
                      })
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label={`อนุมัติ`}
                  name={"approve"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    className="w-full"
                    options={(modalContent.users_approve || []).map((x, i) => ({
                      key: i,
                      value: x.user_id,
                      label: (
                        <div className="flex gap-2 items-center justify-between">
                          <label>
                            {i + 1}. {x.name}
                          </label>
                          {x.management_position_id ? (
                            <Tag color="processing" className="h-fit">
                              {x.management_position_name}
                            </Tag>
                          ) : (
                            <Tag color="warning" className="h-fit">
                              {x.position_name}
                            </Tag>
                          )}
                        </div>
                      ),
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  label={`ออกคำสั่ง`}
                  name={"own"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    className="w-full"
                    options={(modalContent.users_own || []).map((x, i) => ({
                      key: i,
                      value: x.user_id,
                      label: (
                        <div className="flex gap-2 items-center justify-between">
                          <label>
                            {i + 1}. {x.name}
                          </label>
                          {x.management_position_id ? (
                            <Tag color="processing" className="h-fit">
                              {x.management_position_name}
                            </Tag>
                          ) : (
                            <Tag color="warning" className="h-fit">
                              {x.position_name}
                            </Tag>
                          )}
                        </div>
                      ),
                    }))}
                  />
                </Form.Item>
              </>
            )}

            <div className="flex justify-center items-center gap-2">
              <Button
                key="back"
                className="w-1/4"
                onClick={() => {
                  setModalContent({ open: false });
                }}
              >
                ยกเลิก
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                className="bg-blue-500 w-1/4"
                loading={loading}
              >
                บันทึก
              </Button>
            </div>
          </Card>
        </Form>
      </Modal>
    </>
  );
};

export default SettingPage;
