import React from "react";
import { Link } from "react-router-dom";
import { MENU_PATH, USERDATA } from "../config/index";
import { useSelector } from "react-redux";

const HomePage = () => {
  const userState = useSelector((state) => state.auth.authUser) || USERDATA;

  let allMenu = MENU_PATH.filter((x) => !x.deactive);

  if (
    Object.keys(userState?.role || []).filter((x) => x !== "admin").length === 0
  ) {
    allMenu = allMenu.filter((x) => x.path !== "admin");
  }

  if (userState?.role?.admin !== 1) {
    allMenu = allMenu.filter((x) => x.path !== "setting");
  }

  return (
    <div className="md:max-w-full sm:max-w-lg min-h-full bg-white px-8 py-14 sm:px-12 flex justify-center items-center">
      <div className="w-full">
        <div className="grid sm:grid-cols-5 place-items-center gap-12">
          {allMenu.map((x) => (
            <Link
              key={x.label}
              to={{ pathname: "/" + x.path }}
              className={
                (x.path === "admin"
                  ? "bg-red-500"
                  : x.path === "setting"
                  ? "bg-gray-300"
                  : "bg-blue-500") +
                " cursor-pointer scale-100 hover:scale-105 shadow-2xl p-4 rounded flex gap-4 flex-col items-center justify-center relative h-56 w-full"
              }
            >
              <img src={x.image} className="w-3/6" />
              <div className="text-white text-xl font-semibold text-center">
                {x.label}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
